import { styled } from '@mui/material';
import { Box } from '@mui/material';

export const StyledPopoverContainer = styled(Box)`
  padding: 1rem;
  max-height: 25%;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  border-radius: 10px;
`;
