import { Typography, Button, Box } from '@mui/material';
import { styled } from '@mui/system';

interface ChartContainerProps {
  height: number;
}

export const EmptyMessage = styled(Typography)`
  margin-top: 16px;
  color: #878787;
`;

export const StyledButton = styled(Button)`
  font-size: 1rem;
  padding: 0.6rem;
`;

//the +24 is to account for the padding of the card
export const ChartContainer = styled(Box)<ChartContainerProps>`
  display: flex;
  height: ${(props) => (props.height ? `${props.height + 24}px` : '400px')};
  position: relative;
`;
