import { Button, TextField, styled } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { FC, useState } from 'react';
import { Modal } from '../../../ui';

interface DateRangeModalProps {
  start: Date;
  end: Date;
  onSubmit: (start: Date, end: Date) => void;
  onClick?: () => void;
  disabled?: boolean;
}

export const DateRangeModal: FC<DateRangeModalProps> = ({
  start,
  end,
  onSubmit,
  onClick,
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    onClick && onClick();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (start: Date, end: Date) => {
    onSubmit(start, end);
    handleClose();
  };

  return (
    <>
      <Button
        style={{ fontSize: '1rem', padding: '0.6rem' }}
        variant={disabled ? 'containedLight' : 'text'}
        onClick={handleOpen}
      >
        Custom
      </Button>
      <Modal open={open} onClose={handleClose}>
        <CustomDateRangePicker
          start={start}
          end={end}
          onSubmit={handleSubmit}
        />
      </Modal>
    </>
  );
};

interface CustomDateRangePickerProps {
  start: Date | null;
  end: Date | null;
  onSubmit: (startDate: Date, endDate: Date) => void;
}

export const CustomDateRangePicker: FC<CustomDateRangePickerProps> = ({
  start,
  end,
  onSubmit,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(start);
  const [endDate, setEndDate] = useState<Date | null>(end);

  const [startPickerOpen, setStartPickerOpen] = useState(false);
  const [endPickerOpen, setEndPickerOpen] = useState(false);

  const handleOpen = (picker: 'start' | 'end') => {
    if (picker === 'start') {
      setEndPickerOpen(false);
      setStartPickerOpen(true);
      setEndDate(null);
    }

    if (picker === 'end') {
      setStartPickerOpen(false);
      setEndPickerOpen(true);
    }
  };

  const handleChangeAndSubmit = (newValue: any) => {
    setEndDate(newValue);
    if (startDate && newValue) {
      if (startDate <= newValue) {
        onSubmit && onSubmit(startDate, newValue);
      }
    }
    setEndPickerOpen(false);
  };

  return (
    <DatePickerContainer>
      <DatePicker
        onOpen={() => handleOpen('start')}
        closeOnSelect
        open={startPickerOpen}
        onClose={() => setStartPickerOpen(false)}
        label='Start Date'
        value={startDate}
        onChange={(newValue) => {
          setStartDate(newValue);
        }}
        renderInput={(params) => <TextField variant='standard' {...params} />}
      />
      <span>to</span>
      <DatePicker
        disabled={startDate === null}
        minDate={
          startDate
            ? new Date(new Date(startDate).getTime() + 60 * 60 * 24 * 1000)
            : null
        }
        onOpen={() => handleOpen('end')}
        closeOnSelect
        open={endPickerOpen}
        label='End Date'
        value={endDate}
        onChange={handleChangeAndSubmit}
        renderInput={(params) => <TextField variant='standard' {...params} />}
      />
    </DatePickerContainer>
  );
};

const DatePickerContainer = styled('div')`
  display: flex;
  align-items: flex-end;
  gap: 10px;
  padding: 16px 0;
`;
