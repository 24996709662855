import { Popover, Button } from '@mui/material';
import { StyledPopoverContainer } from './styles';

interface ListPopoverProps<T extends string | number> {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  items: T[];
  onSelect: (item: T) => void;
}

export const ListPopover = <T extends string | number>({
  open,
  anchorEl,
  onClose,
  items = [],
  onSelect,
}: ListPopoverProps<T>) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
    >
      <StyledPopoverContainer>
        {items.map((item, index) => (
          <Button
            key={index}
            onClick={() => {
              onSelect(item);
              onClose();
            }}
            variant='containedLight'
          >
            {item}
          </Button>
        ))}
      </StyledPopoverContainer>
    </Popover>
  );
};
