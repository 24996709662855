import { FC } from 'react';
import { format, parseISO } from 'date-fns';
import { LineChart } from '@mui/x-charts/LineChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
interface LineChartProps {
  chartData: any[];
  leftAxis: { label: string };
  rightAxis: { label: string };
}

interface BarChartProps {
  chartData: any[];
}

export const CustomLineChart: FC<LineChartProps> = ({
  chartData,
  leftAxis,
  rightAxis,
}) => {
  return (
    <LineChart
      skipAnimation
      series={[leftAxis, rightAxis]}
      slotProps={{
        legend: {
          labelStyle: {
            fontSize: 16,
            fontWeight: 400,
          },
        },
      }}
      xAxis={[
        {
          label: 'Date',
          dataKey: 'created_at',
          scaleType: 'time',
          valueFormatter: (value: any) =>
            value ? format(value, 'yyyy-MM-dd') : '',
        },
      ]}
      leftAxis='leftAxis'
      rightAxis='rightAxis'
      yAxis={[
        {
          id: 'leftAxis',
          disableTicks: true,
          label: leftAxis.label,
        },
        {
          id: 'rightAxis',
          disableTicks: true,
          label: rightAxis.label,
        },
      ]}
      dataset={chartData}
      margin={{ left: 70, right: 70 }}
      sx={{
        [`.${axisClasses.left} .${axisClasses.label}`]: {
          transform: 'translate(-25px, 0)',
        },
        [`.${axisClasses.right} .${axisClasses.label}`]: {
          transform: 'translate(30px, 0)',
        },
      }}
    />
  );
};

export const CustomBarChart: FC<BarChartProps> = ({ chartData }) => {
  return (
    <BarChart
      dataset={chartData}
      colors={['#6437E4']}
      borderRadius={10}
      margin={{ bottom: 36, left: 28, right: 18 }}
      series={[
        {
          dataKey: 'hours_on',
          label: 'Hours Worn:',
          valueFormatter: (value: number | null) => (value ? `${value}` : '0'),
        },
      ]}
      xAxis={[
        {
          dataKey: 'date',
          scaleType: 'band',
          valueFormatter: (value: string) => format(parseISO(value), 'MMM d'),
        },
      ]}
      yAxis={[
        {
          disableTicks: true,
        },
      ]}
      slotProps={{
        legend: {
          hidden: true,
        },
      }}
    />
  );
};
