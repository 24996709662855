import { styled } from '@mui/system';
import { Box, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';

interface ChartContainerProps {
  height: number;
}

export const BraceSensorDataCardContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BraceSensorDataImageContainer = styled(Box)`
  margin-top: 1.5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`;

export const BraceSensorImg = styled('img')`
  width: 22.5%;
  height: 22.5%;
  margin-left: -1vw;
`;

export const BraceSensorDataNameContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const YearMonthPickerContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 1vh;
  gap: 1vw;
`;

export const StyledIconButton = styled(IconButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: black;
  height: 3rem;
  width: 3rem;
`;

export const YearContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 3rem;
`;

export const StyledButton = styled(Button)`
  color: black;
  padding-left: 0.2vw;
  padding-right: 0.2vw;
`;

export const ChartContainer = styled(Box)<ChartContainerProps>`
  position: relative;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-top: 1.5vh;
  width: 100%;
  height: ${(props) => (props.height ? `${props.height}px` : '300px')};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ChartMetaDataContainer = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 85%;
  margin-top: 1.5vh;
`;
