import { useState, useEffect, useCallback } from 'react';
import {
  getPatientBraceSensorData as fetchPatientBraceSensorData,
  BraceSensorDataModel,
} from '../network'; // Rename import

export const usePatientBraceSensorData = (patientId: number) => {
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [patientBraceSensorData, setPatientBraceSensorData] = useState<{
    results: BraceSensorDataModel[];
    daily_average: number;
    brace_sensor_name: string;
  }>();

  const request = useCallback(
    async (month: number, year: number) => {
      if (!patientId) {
        setError(new Error('Id is not provided'));
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const data = await fetchPatientBraceSensorData(patientId, month, year);
        setPatientBraceSensorData({
          results: data.results,
          daily_average: data.daily_average,
          brace_sensor_name: data.brace_sensor_name,
        });
      } catch (e) {
        if (e instanceof Error) {
          setError(e);
        } else {
          setError(new Error('An unknown error occurred'));
        }
      }

      setLoading(false);
    },
    [patientId]
  );

  useEffect(() => {
    if (selectedDate) {
      request(selectedDate.getMonth() + 1, selectedDate.getFullYear());
    }
  }, [request, selectedDate]);

  return {
    selectedDate,
    setSelectedDate,
    error,
    loading,
    patientBraceSensorData,
  };
};
