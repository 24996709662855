import { useState, useRef, useEffect } from 'react';
import { Card } from '../../../ui';
import { Typography } from '@mui/material';
import BraceSensorIcon from '../../../../assets/image/icons/brace-sensor.png';
import { ListPopover } from '../../../ui';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { usePatientContext } from '../../containers/PatientContainer/usePatientContext';
import { usePatientBraceSensorData } from '../../hooks/usePatientBraceSensorData';
import { CustomBarChart } from '../Chart';

import {
  BraceSensorDataCardContainer,
  BraceSensorDataImageContainer,
  BraceSensorImg,
  BraceSensorDataNameContainer,
  YearMonthPickerContainer,
  YearContainer,
  StyledIconButton,
  StyledButton,
  ChartContainer,
  ChartMetaDataContainer,
} from './styles';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const availableYears = Array.from(
  { length: new Date().getFullYear() - 2024 + 1 },
  (_, i) => 2024 + i
);

export const BraceSensorDataCard = () => {
  const { patient } = usePatientContext();
  const { selectedDate, setSelectedDate, patientBraceSensorData, loading } =
    usePatientBraceSensorData(patient.id);
  const [monthAnchorEl, setMonthAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );
  const [yearAnchorEl, setYearAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );
  const [hasSetInitialHeight, setHasSetInitialHeight] = useState(false);
  const [chartHeight, setChartHeight] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const metaDataContainerRef = useRef<HTMLDivElement>(null);
  const monthPopoverOpen = Boolean(monthAnchorEl);
  const yearPopoverOpen = Boolean(yearAnchorEl);
  const selectedMonth = monthNames[selectedDate.getMonth()];
  const selectedYear = selectedDate.getFullYear();

  const handleMonthSelect = (month: string) => {
    setSelectedDate(new Date(selectedYear, monthNames.indexOf(month), 1));
  };

  const handleMonthClose = () => {
    setMonthAnchorEl(null);
  };

  const handleMonthOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMonthAnchorEl(event.currentTarget);
  };

  const handleYearSelect = (year: number) => {
    setSelectedDate(new Date(year, selectedDate.getMonth(), 1));
  };

  const handleYearClose = () => {
    setYearAnchorEl(null);
  };

  const handleYearOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setYearAnchorEl(event.currentTarget);
  };

  const handlePreviousMonth = () => {
    const newDate = new Date(selectedDate);
    newDate.setMonth(selectedDate.getMonth() - 1);
    setSelectedDate(newDate);
  };

  const handleNextMonth = () => {
    const newDate = new Date(selectedDate);
    newDate.setMonth(selectedDate.getMonth() + 1);
    setSelectedDate(newDate);
  };

  const isNextMonthDisabled = () => {
    const currentDate = new Date(selectedDate.getTime());
    const nextDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));

    return nextDate > new Date();
  };

  const isPreviousMonthDisabled = () => {
    const currentDate = new Date(selectedDate.getTime());
    const previousDate = new Date(
      currentDate.setMonth(currentDate.getMonth() - 1)
    );

    return previousDate < new Date(2024, 0, 1);
  };

  useEffect(() => {
    if (
      containerRef.current &&
      metaDataContainerRef.current &&
      !loading &&
      !hasSetInitialHeight
    ) {
      const cardHeight = containerRef.current.clientHeight;
      const cardRect = containerRef.current.getBoundingClientRect();
      const metaDataRect = metaDataContainerRef.current.getBoundingClientRect();

      const distanceFromCardTop = metaDataRect.top - cardRect.top;
      const chartHeight = Math.round(cardHeight - distanceFromCardTop) + 48; //padding;

      setHasSetInitialHeight(true);
      setChartHeight(chartHeight);
    }
  }, [loading, hasSetInitialHeight]);

  return (
    <Card
      title='Brace Compliance'
      loading={loading}
      height='100%'
      ref={containerRef}
    >
      <BraceSensorDataCardContainer>
        <BraceSensorDataImageContainer>
          <BraceSensorImg src={BraceSensorIcon} alt='Brace Sensor' />
          <BraceSensorDataNameContainer>
            <Typography variant='bodyLarge' fontWeight='bold'>
              Brace Sensor
            </Typography>
            <Typography variant='bodyMedium'>
              {patientBraceSensorData?.brace_sensor_name}
            </Typography>
          </BraceSensorDataNameContainer>
        </BraceSensorDataImageContainer>
        <YearMonthPickerContainer>
          <StyledIconButton
            onClick={handlePreviousMonth}
            disabled={isPreviousMonthDisabled()}
          >
            <ArrowBackIos
              style={{ fontSize: '1.25rem', marginLeft: '0.5rem' }}
            />
          </StyledIconButton>
          <YearContainer>
            <StyledButton variant='text' onClick={handleMonthOpen}>
              <Typography variant='bodyLarge'>{selectedMonth}</Typography>
            </StyledButton>
            <ListPopover
              anchorEl={monthAnchorEl}
              open={monthPopoverOpen}
              onClose={handleMonthClose}
              items={monthNames}
              onSelect={handleMonthSelect}
            />
            <StyledButton variant='text' onClick={handleYearOpen}>
              <Typography variant='bodyLarge'>{selectedYear}</Typography>
            </StyledButton>
            <ListPopover
              anchorEl={yearAnchorEl}
              open={yearPopoverOpen}
              onClose={handleYearClose}
              items={availableYears}
              onSelect={handleYearSelect}
            />
          </YearContainer>
          <StyledIconButton
            onClick={handleNextMonth}
            disabled={isNextMonthDisabled()}
          >
            <ArrowForwardIos style={{ fontSize: '1.25rem' }} />
          </StyledIconButton>
        </YearMonthPickerContainer>
        <ChartContainer height={chartHeight}>
          <ChartMetaDataContainer ref={metaDataContainerRef}>
            <Typography variant='bodyMedium'>Daily Average</Typography>
            <Typography variant='bodyLarge' fontWeight='bold'>
              {patientBraceSensorData &&
                patientBraceSensorData?.daily_average.toFixed(2)}{' '}
              hours
            </Typography>
          </ChartMetaDataContainer>
          <CustomBarChart chartData={patientBraceSensorData?.results || []} />
        </ChartContainer>
      </BraceSensorDataCardContainer>
    </Card>
  );
};
